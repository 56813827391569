import React from 'react'
import { colors } from 'utils/helpers/style/colors'
import {
  IconInstagram,
  IconLinkedinOutlined,
  IconTwitterOutlined,
  IconXOutlined,
  IconYoutubeOutlined,
} from 'components/atoms/icon'
import styles from '../../../styles/components/organisms/footerMobile.module.scss'
import urls from 'utils/helpers/url'
import elementId from 'utils/helpers/elementId/elementIds'
import Image from 'next/image'
import { trackFooterClick } from 'services/amplitude/seva20Tracking'
import { getLocalStorage } from 'utils/handler/localStorage'
import { UTMTagsData } from 'utils/types/utils'
import { LocalStorageKey } from 'utils/enum'
import { useUtils } from 'services/context/utilsContext'
import {
  trackEventCountly,
  valueMenuTabCategory,
} from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'

const SevaLogo = '/revamp/icon/logo-on-dark.webp'
const ISOIcon = '/revamp/icon/iso.webp'
const FacebookLogo = '/revamp/icon/facebook-outline.png'

export interface FooterProps {
  pageOrigination?: string
  additionalContainerClassname?: string
}
export const FooterMobile = ({
  pageOrigination,
  additionalContainerClassname = '',
}: FooterProps) => {
  const UTMTags = getLocalStorage<UTMTagsData>(LocalStorageKey.UtmTags)
  const { mobileWebFooterMenus } = useUtils()

  const dataTestId = (code: string) => {
    switch (code) {
      case 'tentang-seva':
        return elementId.Footer.TentangKami
      case 'syarat-ketentuan':
        return elementId.Footer.SyaratKetentuan
      case 'kebijakan-privasi':
        return elementId.Footer.KebijakanPrivasi
      case 'hubungi-kami':
        return elementId.Footer.HubungiKami
    }
  }

  const formatMenuUrl = (url: string, isWithoutTrailing?: boolean) => {
    // wordpress pages need to have trailing slash
    let urlWithTrailingSlash = url

    if (isWithoutTrailing) return urlWithTrailingSlash

    if (url?.slice(-1) !== '/') {
      urlWithTrailingSlash = url + '/'
    }

    if (!urlWithTrailingSlash.startsWith('https://')) {
      return 'https://' + urlWithTrailingSlash
    }

    return urlWithTrailingSlash
  }
  const trackCountlyFooter = (menuCode: string, menuUrl: string) => {
    if (pageOrigination && pageOrigination.length !== 0) {
      trackEventCountly(CountlyEventNames.WEB_FOOTER_CLICK, {
        PAGE_ORIGINATION: pageOrigination.includes('PDP')
          ? pageOrigination + valueMenuTabCategory()
          : pageOrigination,
        PAGE_DIRECTION_URL: formatMenuUrl(menuUrl, menuCode === 'hubungi-kami'),
      })
    }
  }
  const handleClickMenu = (menuCode: string, menuUrl: string) => {
    trackFooterClick({
      Page_Origination_URL: window.location.href,
      Menu: menuCode,
    })
    trackCountlyFooter(menuCode, menuUrl)
  }

  return (
    <footer className={`${styles.container} ${additionalContainerClassname}`}>
      <div className={styles.contentContainer}>
        <Image
          src={SevaLogo}
          width={61}
          height={31}
          alt="Logo SEVA Footer"
          className={styles.sevaLogo}
          loading="lazy"
        />
        <span className={styles.footerText}>
          SEVA - Platform yang berada di bawah Astra Financial yang menyediakan
          layanan pembiayaan mobil baru dengan didukung oleh perusahaan
          pembiayaan dan dealer resmi dari Astra Group
        </span>
        <div className={styles.linkedTextWrapper}>
          {mobileWebFooterMenus?.length > 0 &&
            mobileWebFooterMenus.map((item, index) => (
              <a
                aria-label={`Cari tahu lebih lanjut tentang ${item.menuName}`}
                key={index}
                href={formatMenuUrl(
                  item.menuUrl,
                  item.menuCode === 'hubungi-kami',
                )}
                rel="noreferrer noopener"
                target="_blank"
                onClick={() => {
                  if (item.menuCode === 'hubungi-kami') {
                    window.dataLayer.push({
                      event: 'web_main_footer_hubungi_kami_click',
                    })
                  }
                  trackCountlyFooter(item.menuCode, item.menuUrl)
                }}
                data-testid={dataTestId(item.menuCode)}
              >
                {item.menuName}
              </a>
            ))}
        </div>
        <div className={styles.socialWrapper}>
          <a
            aria-label="Cari tahu lebih lanjut tentang Instagram Seva"
            href={urls.externalUrls.instagram}
            onClick={() =>
              handleClickMenu('Instagram', urls.externalUrls.instagram)
            }
            rel="noreferrer noopener"
            target="_blank"
            datatest-id={elementId.Footer.LogoInstagram}
            style={{ height: 24 }}
          >
            <IconInstagram
              width={24}
              height={24}
              color={colors.white}
              alt="SEVA Instagram Icon"
            />
          </a>
          <a
            aria-label="Cari tahu lebih lanjut tentang Twitter Seva"
            href={urls.externalUrls.twitter}
            onClick={() =>
              handleClickMenu('Twitter', urls.externalUrls.twitter)
            }
            rel="noreferrer noopener"
            target="_blank"
            datatest-id={elementId.Footer.LogoTwitter}
            style={{ height: 24 }}
          >
            <IconXOutlined
              width={24}
              height={24}
              color={colors.white}
              alt="SEVA X Icon"
            />
          </a>
          <a
            aria-label="Cari tahu lebih lanjut tentang Facebook Seva"
            href={urls.externalUrls.facebook}
            onClick={() =>
              handleClickMenu('Facebook', urls.externalUrls.facebook)
            }
            rel="noreferrer noopener"
            target="_blank"
            datatest-id={elementId.Footer.LogoFacebook}
            style={{
              height: 24,
              width: 24,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image
              src={FacebookLogo}
              width={17.6}
              height={17.6}
              alt="SEVA Facebook Icon"
            />
          </a>
          <a
            aria-label="Cari tahu lebih lanjut tentang Youtube Seva"
            href={urls.externalUrls.youtube}
            onClick={() =>
              handleClickMenu('Twitter', urls.externalUrls.youtube)
            }
            rel="noreferrer noopener"
            target="_blank"
            datatest-id={elementId.Footer.LogoYoutube}
            style={{ height: 24 }}
          >
            <IconYoutubeOutlined
              width={24}
              height={24}
              color={colors.white}
              alt={'SEVA Youtube Icon Footer'}
            />
          </a>
          <a
            aria-label="Cari tahu lebih lanjut tentang Linked In Seva"
            href={urls.externalUrls.linkedin}
            onClick={() =>
              handleClickMenu('Twitter', urls.externalUrls.linkedin)
            }
            rel="noreferrer noopener"
            target="_blank"
            datatest-id={elementId.Footer.LogoLinkedin}
            style={{ height: 24 }}
          >
            <IconLinkedinOutlined
              width={24}
              height={24}
              color={colors.white}
              alt={'SEVA Linked In Icon Footer'}
            />
          </a>
        </div>
        <div className={styles.isoWrapper}>
          <Image
            src={ISOIcon}
            width={29}
            height={29}
            alt="CBQA ISO 27001"
            datatest-id={elementId.Footer.LogoISO}
            loading="lazy"
          />
          <span className={styles.footerText}>
            Kami mengambil langkah-langkah untuk membantu
            <br />
            memastikan data kamu tetap aman dengan ISO 27001.
          </span>
        </div>
        <div className={styles.divider} />
        <div className={styles.copyrightText}>© 2023 Copyright SEVA</div>
        <p className={styles.utmText} suppressHydrationWarning>
          {UTMTags?.utm_source ? 'Source: ' + UTMTags?.utm_source : ''}
          <br />
          {UTMTags?.utm_campaign ? 'Campaign: ' + UTMTags?.utm_campaign : ''}
        </p>
      </div>
    </footer>
  )
}
